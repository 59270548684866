var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "open-on-click": false,
        "close-on-click": false,
        "offset-y": "",
        bottom: "",
        nudgeTop: _vm.nudgeTop,
        disabled: _vm.disabled,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (_) {
            return [
              _c(
                "div",
                {
                  attrs: {
                    role: "combobox",
                    "aria-expanded": "" + _vm.isMenuShowing,
                    "aria-owns": _vm.listboxId,
                    "aria-haspopup": "listbox",
                  },
                },
                [
                  _c(
                    "v-text-field",
                    _vm._b(
                      {
                        ref: "input",
                        attrs: {
                          disabled: _vm.disabled,
                          label: _vm.label,
                          "aria-autocomplete": "list",
                          "aria-controls": _vm.listboxId,
                          "aria-activedescendant": _vm.selectedItemId,
                          clearable: "",
                        },
                        on: {
                          keydown: _vm.onInputKeydown,
                          "click:clear": function ($event) {
                            return _vm.$emit("input", "")
                          },
                          focus: _vm.openMenu,
                          blur: _vm.onInputBlur,
                          "update:error": function ($event) {
                            return _vm.$emit("update:error", $event)
                          },
                        },
                        model: {
                          value: _vm.displayText,
                          callback: function ($$v) {
                            _vm.displayText = $$v
                          },
                          expression: "displayText",
                        },
                      },
                      "v-text-field",
                      _vm.$attrs,
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isMenuShowing,
        callback: function ($$v) {
          _vm.isMenuShowing = $$v
        },
        expression: "isMenuShowing",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            {
              ref: "list",
              staticClass: "ma-0 pa-0",
              style: _vm.listStyle,
              attrs: {
                id: _vm.listboxId,
                role: "listbox",
                "aria-label": _vm.label,
                tabindex: "-1",
                column: "",
                "align-start": "",
              },
            },
            [
              _vm.filteredMenuItems.length > 0
                ? _vm._l(_vm.filteredMenuItems, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        ref: "listitem",
                        refInFor: true,
                        staticClass: "ma-0 px-2 py-3 subheading",
                        class: { "grey lighten-3": _vm.focusedItemIndex === i },
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: _vm.id + "-" + item.value,
                          role: "option",
                          "aria-selected": _vm.value === item.value,
                          "data-cr-testing": _vm.testingSelector(item.text),
                          tabindex: "-1",
                        },
                        on: {
                          mouseenter: function ($event) {
                            return _vm.setFocus(i, false)
                          },
                          keydown: function ($event) {
                            return _vm.setSelection(item.value)
                          },
                          click: function ($event) {
                            return _vm.setSelection(item.value)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.text) +
                            "\n                "
                        ),
                      ]
                    )
                  })
                : _c(
                    "span",
                    {
                      staticClass: "ma-0 px-2 py-3 subheading",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("NO_DATA")) +
                          "\n            "
                      ),
                    ]
                  ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }