var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      style: _vm.buttonStyle,
      attrs: {
        text: "",
        outlined: "",
        block: "",
        loading: _vm.loading || _vm.authInProgress,
        disabled: _vm.loading || _vm.authInProgress,
        "data-testing": "credential-login-button",
      },
      on: { click: _vm.credentialLogin },
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v(_vm._s(_vm.icon || "pin"))])],
            1
          ),
          _vm._v(" "),
          _c("v-flex", { attrs: { row: "", wrap: "" } }, [
            _c(
              "div",
              {
                staticClass: "text-none px-3",
                staticStyle: {
                  "font-family": '"Google Sans",arial,sans-serif',
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.label || _vm.$t("LOGIN_CREDENTIAL_ANONYMOUS"))
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }