var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoadingShown
        ? _c("v-btn", {
            style: _vm.buttonStyle,
            attrs: {
              text: "",
              outlined: "",
              block: "",
              loading: "",
              disabled: "",
              "data-testing": "google-login-button",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoadingShown,
              expression: "!isLoadingShown",
            },
          ],
          ref: "googleButtonContainer",
        },
        [
          _vm.googleScriptShouldBeLoadedByNow
            ? _c("v-flex", { staticClass: "google-placeholder pa-2" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("LOGIN_GOOGLE_PRIVACY_BLOCKER_TEXT"))),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }