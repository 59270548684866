var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loggingIn
        ? [_c("loading", { attrs: { message: _vm.$t("JOIN_LOGGING_IN") } })]
        : _vm.creatingUser
        ? [
            _c("loading", {
              attrs: { message: _vm.$t("JOIN_CREATING_ACCOUNT") },
            }),
          ]
        : _vm.error && !_vm.retryableError
        ? _c(
            "v-alert",
            {
              staticClass: "mb-4",
              attrs: {
                type: "error",
                icon: "error",
                dense: "",
                value: _vm.error,
              },
            },
            [_vm._v(_vm._s(_vm.error))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h1", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("JOIN_TITLE", { competitionName: _vm.competition.name })
          ),
        },
      }),
      _vm._v(" "),
      _vm.competition.id
        ? [
            _vm.error && _vm.retryableError
              ? _c(
                  "v-alert",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      type: "error",
                      icon: "error",
                      dense: "",
                      value: _vm.error,
                    },
                  },
                  [_vm._v(_vm._s(_vm.error))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("announcement"),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c("login-buttons", {
              attrs: {
                mode: "join",
                providers: _vm.providers,
                competitionId: _vm.competitionId,
                "credential-code": _vm.error ? undefined : _vm.credentialCode,
              },
              on: { confirm: _vm.onLoginSuccess },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }