// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/side-image-360.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/side-image-720.webp");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/side-image-1080.webp");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/side-image-2k.webp");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/side-image-4k.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "\n[data-v-09512f3f] h1 {\n  color: #3c4886;\n}\n.side-image[data-v-09512f3f] {\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n}\n@media (max-height: 360px) {\n.side-image[data-v-09512f3f] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n}\n@media (min-height: 361px) and (max-height: 720px) {\n.side-image[data-v-09512f3f] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n}\n@media (min-height: 721px) and (max-height: 1080px) {\n.side-image[data-v-09512f3f] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n}\n@media (min-height: 1081px) and (max-height: 1440px) {\n.side-image[data-v-09512f3f] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n}\n@media (min-height: 1441px) {\n.side-image[data-v-09512f3f] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n}\n.side-image span[data-v-09512f3f] {\n  color: white;\n  background: rgba(35, 72, 124, 0.8);\n  text-align: center;\n  padding: 10px 0;\n  width: 100%;\n}\n.side-image span a[data-v-09512f3f] {\n  color: inherit;\n}\n", ""]);
// Exports
module.exports = exports;
