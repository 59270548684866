var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              _c("v-col", { staticClass: "pa-0 d-none d-md-block" }, [
                _c("div", { staticClass: "side-image d-flex align-end" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("PAGE_TAGLINE")) + " | "),
                    _c(
                      "a",
                      { attrs: { href: _vm.policyUrl, target: "_blank" } },
                      [_vm._v(_vm._s(_vm.$t("PAGE_TERMS_AND_POLICY")))]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 d-flex justify-center align-center" },
                [
                  _c(
                    "div",
                    { staticStyle: { "max-width": "70%" } },
                    [_c("router-view", { key: _vm.$route.fullPath })],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }