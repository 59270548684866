class GooglePlugin
{
    _onSigninSuccessCallbacks = new Map<Symbol, (user: { credential }) => void>();

    constructor(private clientId: string) {}

    private _loading: Promise<void>;
    load(): Promise<void>
    {
      if (this._loading === undefined) {
        this._loading = (async () => {
          // Wait for window.google to be available from accounts.google.com/gsi/client script
          while (window['google'] === undefined)
          {
            await new Promise(res => setTimeout(res, 50));
          }
          const google = window['google'].accounts.id;
          google.initialize({
            client_id: this.clientId,
            callback: (data) => this.onSiginSuccess(data),
          });
        })();
      }
      return this._loading;
    }

    async registerButton(parentElement: HTMLElement, callback: (user: { credential }) => void, scopes: string[])
    {
        await this.load();
        const key = Symbol();
        window['google'].accounts.id.renderButton(parentElement, {
          scope: scopes,
          size: 'large',
          width: 320,
        });
        this._onSigninSuccessCallbacks.set(key, callback);
    }

    private onSiginSuccess(data: { credential })
    {
      for (const fn of this._onSigninSuccessCallbacks.values())
      {
        fn(data);
      }
    }
}

declare module 'vue/types/vue' {
  interface Vue {
    $google: GooglePlugin
  }
}

export default {
    install(vue, config: { clientId: string })
    {
        const googlePlugin = new GooglePlugin(config.clientId);
        googlePlugin.load();
        vue.prototype.$google = googlePlugin
    }
}
