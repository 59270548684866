import LoginStrings from "./definitions/loginStrings";
import RegistrationStrings from './definitions/registrationStrings';
import InvitationStrings from './definitions/invitationStrings';
import JoinStrings from './definitions/joinStrings';
import LtiStrings from './definitions/ltiStrings';
import LogoutStrings from './definitions/logoutStrings';
import PaymentStrings from './definitions/paymentStrings';
import PageStrings from './definitions/pageStrings';
import RedirectStrings from './definitions/redirectStrings';
import SignupStrings from './definitions/signupStrings';
import BusinessUnitStrings from './definitions/businessUnitStrings';

import Config from '@/config';


const messages = {};

const strings = [
    LoginStrings,
    RegistrationStrings,
    InvitationStrings,
    JoinStrings,
    LtiStrings,
    LogoutStrings,
    PaymentStrings,
    PageStrings,
    RedirectStrings,
    SignupStrings,
    BusinessUnitStrings,
];
export default function createMessages(domain:string /* 'VACR' or 'USCR */)
{
    for (const string of strings) {
        for (const key of Object.keys(string)) {
            if (!messages[key]) {
                messages[key] = {};
            }
            
            for ( const stringKey of Object.keys(string[key]) ){
                if( string[key][stringKey] instanceof Object ){
                    for( const objectKey of Object.keys(string[key][stringKey]) ){
                        if(objectKey === domain){
                            string[key][stringKey] = string[key][stringKey][objectKey];
                        }
                    }
                }
            }
            
            Object.assign(messages[key], messages[key], ...string[key]);
        }
    }

    return messages;
}
