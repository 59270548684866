






































































import Vue from 'vue';
import StoreAction from '@interfaces/storeAction';
import { setTimeout } from 'timers';
import Loading from '@utils/Loading.vue';
import LoginButtons from '../login/LoginButtons.vue';
import ErrorText from '@utils/ErrorText.vue';
import Announcement from '@utils/Announcement.vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import Config from '../../config';
import AddUserMethod from '@/interfaces/addUserMethod';
import { CreateUserParams } from '@/utils/userCreation/createUserParams';

@Component({
	components: { Loading, LoginButtons, ErrorText, Announcement }
})
export default class Registration extends Vue {
	@Action(StoreAction.SetError) setError;
	@Action(StoreAction.ResetError) resetError;
	@Getter(StoreGetter.UserApiClient) userApiClient;
  @Action(StoreAction.GetSsoToken) getSsoToken: () => Promise<string>;
  @Action(StoreAction.TryLogin) tryLogin: () => Promise<boolean>;
	@Getter(StoreGetter.IsLoading) loading: boolean;
	@Getter(StoreGetter.GetErrorMessage) error;
	isErrorState: boolean = false;
	course = {};
	courseCode = '';
	retryableError: boolean = true;
	maxCodeLength: number = 12;
	courseCodeIcon = undefined;
  creatingUser = false;
  
	get isValidCourseCode() {
		return this.courseCode.length === 12
	}

	async created() {
		if(this.$route.params.cid){
      this.courseCode = this.$route.params.cid;
      const validatedCode = await this.userApiClient.getCourseCode(this.courseCode);
      this.course = { courseName: validatedCode.courseName };
    }
	}

  destroyed()
  {
    this.resetError();
  }

	async onCourseCodeChange() {
		if (this.courseCode.length === 12) {
			this.course = await this.userApiClient.getCourseCode(this.courseCode);
			this.resetError();
		} else {
			if (this.courseCode.length < 12) this.resetError();
		}
	}
  async onLoginSuccess() {
    try
    {
      this.creatingUser = true;
      await this.$store.dispatch(StoreAction.CreateUser, new CreateUserParams(AddUserMethod.courseCode, this.courseCode));
    }
    catch
    {
      this.creatingUser = false;
    }
	}
}
