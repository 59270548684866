var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "mb-6" }, [
        _vm._v(_vm._s(_vm.$t("LOGIN_TITLE"))),
      ]),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticClass: "mb-4",
          attrs: {
            type: "error",
            icon: "error",
            dense: "",
            value: !!_vm.error,
          },
        },
        [_vm._v(_vm._s(_vm.error))]
      ),
      _vm._v(" "),
      _c("announcement"),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("login-buttons", {
        attrs: {
          providers: ["google", "azure", "credential_anonymous"],
          "credential-code": _vm.error ? undefined : _vm.credentialCode,
          mode: "",
        },
        on: { confirm: _vm.confirm },
      }),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "registration" } } }, [
        _vm._v(_vm._s(_vm.$t("LOGIN_INVITATION_LOGIN_TEXT"))),
      ]),
      _vm._v(" "),
      _vm.featureSignInAsAnonymousUserEnabled
        ? [
            _c("br"),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { target: "_blank", href: _vm.anonymousUsersArticleUrl },
              },
              [_vm._v(_vm._s(_vm.$t("LOGIN_ANONYMOUS_USER_HELP_MESSAGE")))]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("a", { attrs: { target: "_blank", href: _vm.loginSupportUrl } }, [
        _vm._v(_vm._s(_vm.$t("LOGIN_ERROR_HELP_MESSAGE"))),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }