var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            {
              staticClass: "my-auto",
              staticStyle: { "max-width": "80%" },
              attrs: {
                "grid-list-xs": "",
                fluid: "",
                "justify-center": "",
                "align-center": "",
              },
            },
            [
              _c(
                "v-layout",
                {
                  staticStyle: { width: "100%" },
                  attrs: { row: "", wrap: "" },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "leftSideClass",
                      attrs: { "pa-4": "", "d-none": _vm.hideLeftSide },
                    },
                    [
                      _vm.isUSCR
                        ? _c("UscrOrganizationSignUp", {
                            on: {
                              submitting: function (val) {
                                return (_vm.submittingLeft = val)
                              },
                            },
                          })
                        : _c("VacrInstructorSignUp", {
                            on: {
                              cancel: function ($event) {
                                _vm.submittingLeft = false
                              },
                              completed: function ($event) {
                                _vm.completedLeft = true
                              },
                              submitting: function (val) {
                                return (_vm.submittingLeft = val)
                              },
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rightSideClass",
                      class: _vm.hideLeftSide ? "whiteBackground" : "",
                      attrs: { "pa-4": "", "d-none": _vm.hideRightSide },
                    },
                    [
                      _vm.isUSCR
                        ? [
                            _c("UscrInstructorSignUp", {
                              on: {
                                cancel: function ($event) {
                                  _vm.submittingRight = false
                                },
                                completed: function ($event) {
                                  _vm.completedRight = true
                                },
                                submitting: function (val) {
                                  return (_vm.submittingRight = val)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.submittingRight === false
                              ? _c("v-divider", { staticClass: "mt-2 mb-4" })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.submittingRight === false
                        ? _c("StudentRegistration")
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showErrorDialog
            ? _c("error-dialog", {
                attrs: { error: _vm.error },
                on: {
                  cancel: _vm.toggleShowErrorDialog,
                  confirm: _vm.toggleShowErrorDialog,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }